import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';
import { AuthenticationService } from '../service/authentication.service';
import { Role } from '../model/role';
import { AuthorizationService } from '../service/authorization.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

    logoUrl: string;
    currentUser: User;

    isUserRol: boolean;
    isLocalBreaksRol: boolean;
    isAncineRol: boolean;
    isLegalRol: boolean;
    isSuperAdminRol: boolean;
    isAdminUsersRol: boolean;
    isAdminContentRol: boolean;
    isMicroservicesRol: boolean;
    isLogRol: boolean;
    isAdminPress: boolean;

    languageList = [
        { code: 'en', label: 'english' },
        { code: 'es', label: 'español' },
        { code: 'pt', label: 'português' }
    ];

    private unsubscription$: Subject<void>;

    constructor(
            private translate: TranslateService,
            private authenticationService: AuthenticationService,
            private authorizationService: AuthorizationService) {

        this.unsubscription$ = new Subject<void>();
        this.logoUrl = environment.bucketUrlImages + 'logo_tapkit_white.png';
    }

    ngOnInit(): void {
        this.defineLanguage();
        this.authenticationService
            .currentUser$
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(user => this.defineAccess(user));
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    selectorLanguage(language: string) {
        this.translate.use(language);
        sessionStorage.setItem('locale', language);
    }

    logout() {
        this.authenticationService.logout();
    }

    private defineAccess(user: User): void {
        this.currentUser = user;
        this.isSuperAdminRol = this.authorizationService.isAuthorized([Role.SuperAdmin]);
        this.isAdminContentRol = this.authorizationService.isAuthorized([Role.AdminContent]);
        this.isAncineRol = this.authorizationService.isAuthorized([Role.Ancine]);
        this.isLegalRol = this.authorizationService.isAuthorized([Role.Legal]);
        this.isAdminUsersRol = this.authorizationService.isAuthorized([Role.AdminUsers]);
        this.isLogRol = this.authorizationService.isAuthorized([Role.Logs]);
        this.isLocalBreaksRol = this.authorizationService.isAuthorized([Role.Localbreaks]);
        this.isAdminPress = this.authorizationService.isAuthorized([Role.AdminPress]);
        this.isMicroservicesRol = this.authorizationService.isAuthorized([Role.Microservices]);
    }

    private defineLanguage(): void {
        const browserLang = this.translate.getBrowserLang();
        this.translate.addLangs(['en', 'es', 'pt']);
        let locale = sessionStorage.getItem('locale');

        if (!locale) {
            locale = browserLang.match(/en|es|pt/) ? browserLang : 'en';
            sessionStorage.setItem('locale', locale);
        }
        this.translate.use(locale);
        this.translate.setDefaultLang(locale);
    }

}
