import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


    passwordForm: FormGroup;

    // currentPasswordCtrl = new FormControl('', Validators.required);
    // newPasswordCtrl = new FormControl('', Validators.required);
    // confirmPasswordCtrl = new FormControl('', Validators.required);

    loading = false;
    submitted = false;
    errorChangePassword = false;
    successChangePassword = false;


    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.passwordForm = this.formBuilder.group({
            currentPasswordCtrl: ['', Validators.required],
            newPasswordCtrl: ['', Validators.required],
            confirmPasswordCtrl: ['', Validators.required]
        });
    }

    changePassword(): void {
        this.submitted = true;
        this.errorChangePassword = false;
        this.successChangePassword = false;

        if (this.passwordForm.controls.currentPasswordCtrl.invalid ||
             this.passwordForm.controls.newPasswordCtrl.invalid ||
             this.passwordForm.controls.confirmPasswordCtrl.invalid) {
            return;
        }
        this.loading = true;
        this.authenticationService.changePassword$(
            this.passwordForm.controls.currentPasswordCtrl.value,
            this.passwordForm.controls.newPasswordCtrl.value
        ).subscribe(
            () => {
                this.loading = false;
                this.successChangePassword = true;
                this.passwordForm.reset();
                this.submitted = false;
                // Limpiar errores de validación
                Object.keys(this.passwordForm.controls).forEach(key => {
                    this.passwordForm.controls[key].setErrors(null);
                });
            },
            (error) => {
                this.errorChangePassword = true;
                this.loading = false;
            }
        );
    }

}
