/** Modulos del Framework */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';

/** Componentes propios */
import { EpgSubscriptionComponent } from './epg-subscription/epg-subscription.component';
import { NetworkListComponent } from '../shared/network-list/network-list.component';
import { FeedSearchComponent } from './epg-subscription/feed-search/feed-search.component';
import { FeedGridComponent } from './epg-subscription/feed-grid/feed-grid.component';
import { FeedConfirmComponent } from './epg-subscription/feed-confirm/feed-confirm.component';
import { DialogConfirmComponent } from './epg-subscription/feed-confirm/dialog-confirm/dialog-confirm.component';
import { ConfigurationGridComponent } from './configuration-grid/configuration-grid.component';
import { FieldSelectorComponent } from './configuration-grid/field-selector/field-selector.component';
import { ConfigConfirmComponent } from './configuration-grid/config-confirm/config-confirm.component';
import { EpgComponent } from './epg.component';
import { EpgRoutingModule } from './epg-routing.module';
import { ConfigFeedGridComponent } from './configuration-grid/config-feed-grid/config-feed-grid.component';
import { DialogConfigComponent } from './configuration-grid/config-confirm/dialog-config/dialog-config.component';
import { LegalComponent } from './legal/legal.component';
import { LegalFeedGridComponent } from './legal/legal-feed-grid/legal-feed-grid.component';
import { FeedDownloadComponent } from './legal/feed-download/feed-download.component';
import { LegalFeedSearchComponent } from './legal/legal-feed-search/legal-feed-search.component';
import { FilterReportComponent } from './legal/filter-report/filter-report.component';
import { ModuleSelectComponent } from './configuration-grid/module-select/module-select.component';
import { MonthlyComponent } from './monthly-grids/monthly/monthly.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import { VisualLogsComponent } from './visual-logs/visual-logs.component';
import { MatCardModule } from '@angular/material/card';
import { AncineComponent } from './ancine/ancine.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import {MatExpansionModule} from '@angular/material/expansion';
import { AncineGridComponent } from './ancine/ancine-feed-grid/ancine-feed-grid.component';
import { AncineUtilsDialogsComponentComponent } from './ancine/ancine-feed-grid/utils-dialogs/ancine-utils-dialogs-component/ancine-utils-dialogs-component.component';
import { MonthlyUtilsDialogsComponentComponentComponent } from './monthly-grids/monthly/utils-dialog/monthly-utils-dialogs-component-component/monthly-utils-dialogs-component-component.component';
import { NetworksComponent } from './networks/networks/networks.component';
import { NetworkUtilsDialogComponent } from './networks/networks/utils-dialog/network-utils-dialog/network-utils-dialog.component';
import { NetworksUploadComponent } from './networks-upload/networks-upload.component';
import { WebsitesUtilsDialogComponent } from './networks/networks/utils-dialog/websites-utils-dialog/websites-utils-dialog.component';
import { FeedsUtilsDialogComponent } from './networks/networks/utils-dialog/feeds-utils-dialog/feeds-utils-dialog.component';
import { UsersComponent } from './users/users.component';
import { UsersDialogComponent } from './users/users-dialog/users-dialog.component';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { NetworksUploadUtilsDialogComponent } from './networks-upload/utils-dialog/networks-upload-utils-dialog/networks-upload-utils-dialog.component';
import { NewsComponent } from './news/news/news.component';
import { NewsDialogsComponent } from './news/news/utils-dialog/news-dialogs/news-dialogs.component';
import { NewslettersComponent } from './newsletters/newsletters.component';
import { NewsletterEditorComponent } from './newsletters/newsletter-editor/newsletter-editor.component';
import { NewsletterSentTableComponent } from './newsletters/newsletter-sent-table/newsletter-sent-table.component';
import { NewsletterDraftTableComponent } from './newsletters/newsletter-draft-table/newsletter-draft-table.component';
import { ProfileComponent } from './profile/profile.component';
import { AccountComponent } from './profile/account/account.component';
import { PersonalDataComponent } from './profile/personal-data/personal-data.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';


@NgModule({
    declarations: [
        EpgSubscriptionComponent,
        NetworkListComponent,
        FeedSearchComponent,
        FeedGridComponent,
        FeedConfirmComponent,
        DialogConfirmComponent,
        ConfigurationGridComponent,
        FieldSelectorComponent,
        ConfigConfirmComponent,
        EpgComponent,
        ConfigFeedGridComponent,
        DialogConfigComponent,
        LegalComponent,
        LegalFeedGridComponent,
        FeedDownloadComponent,
        LegalFeedSearchComponent,
        FilterReportComponent,
        DialogConfirmComponent,
        NewsComponent,

        MonthlyComponent,
        ModuleSelectComponent,
        VisualLogsComponent,
        AncineComponent,
        AncineGridComponent,
        AncineUtilsDialogsComponentComponent,
        MonthlyUtilsDialogsComponentComponentComponent,
        NetworksComponent,
        NetworkUtilsDialogComponent,
        NetworksUploadComponent,
        WebsitesUtilsDialogComponent,
        FeedsUtilsDialogComponent,
        UsersComponent,
        UsersDialogComponent,
        NetworksUploadUtilsDialogComponent,
        NewsDialogsComponent,
        NewslettersComponent,
        NewsletterEditorComponent,
        NewsletterSentTableComponent,
        NewsletterDraftTableComponent,
        ProfileComponent,
        AccountComponent,
        PersonalDataComponent,
        ChangePasswordComponent

    ],
    entryComponents: [
        DialogConfirmComponent,
        DialogConfigComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatIconModule,
        MatTooltipModule,
        MatDialogModule,
        MatListModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        DragDropModule,
        BrowserAnimationsModule,
        TranslateModule,
        MatSnackBarModule,
        MatChipsModule,
        MomentDateModule,
        EpgRoutingModule,
        MatCardModule,
        MatIconModule,
        MatMomentDateModule,
        MatTabsModule,
        MatExpansionModule,
        QuillModule.forRoot()
    ]
})
export class EpgModule { }
