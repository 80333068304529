import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Network } from 'src/app/shared/model/network';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-network-list',
    templateUrl: './network-list.component.html',
    styleUrls: ['./network-list.component.css']
})
export class NetworkListComponent {

    @Input()
    networks$: Observable<Network[]>;

    @Input()
    loadingNetworks: boolean;

    @Input()
    style: any;

    @Input()
    activateStyle: any;

    @Input()
    deactivateStyle: any;

    @Output()
    public clickNetwork = new EventEmitter<string>();

    logoUrl: string;

    constructor() {
        this.logoUrl = environment.bucketUrlImagesNetworks;
    }

}
