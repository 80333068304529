/************************************************
 * Ambientes definidos por variables de entorno *
 ***********************************************/

// tslint:disable-next-line: no-string-literal
const URL_BUCKET = window['env']['url_bucket'];
// tslint:disable-next-line: no-string-literal
const URL_API = window['env']['url_api'];

const URL_MONTHLY = window['env']['url_monthly'];
// tslint:disable-next-line: no-string-literal
const PROD = window['env']['prod'];

export const environment = {
    production: PROD,

    /** Session Timeout en segundos */
    timeout: 900,        // 15 minutos
    timeoutPopup: 30,    // 30 segundos

    /** URIs Bucket S3 */
    bucketUrlFeeds: `${URL_BUCKET}/images`,
    bucketUrlImages: `${URL_BUCKET}/images/`,
    bucketUrlImagesNetworks: `${URL_BUCKET}/images/networks/`,
    bucketUrlImagesNetworksLogo: `${URL_BUCKET}/images/networks/logos/`,
    bucketUrlImagesNewsLogo: `${URL_BUCKET}/images/news/`,

    /** URIs Cloud-EPG Service */
    apiEpgUrlDownload: `${URL_API}/epg/shows/download/`,
    apiEpgUrlLogVersion: `${URL_API}/epg/shows/logversion/`,
    apiEpgUrlLegalDownload: `${URL_API}/epg/shows/legal/download/`,

    /** URIs Cloud-Domain Service */
    apiDomainGetLanguages: `${URL_API}/domain/languages`,
    apiDomainUrlGetModules: `${URL_API}/domain/modules`,
    apiDomainUrlGetConfigurations: `${URL_API}/domain/configurations/module/`,
    apiDomainUrlPostConfigurations: `${URL_API}/domain/configurations/settings`,
    apiDomainUrlGetConfigAlias: `${URL_API}/domain/configurations/alias`,
    apiDomainUrlGetNetwork: `${URL_API}/domain/networks`,
    apiDomainUrlPostNetworkSubscription: `${URL_API}/domain/subscriptionNetwork/subscribe`,
    apiDomainUrlGetNetworkAncine: `${URL_API}/domain/networks/ancine`,
    apiDomainUrlGetFeed: `${URL_API}/domain/feeds`,
    apiDomainUrlGetUser: `${URL_API}/domain/users/data`,
    apiDomainUrlGetRoles: `${URL_API}/domain/roles`,
    apiDomainUrlGetUserProfiles: `${URL_API}/domain/userProfiles`,
    apiDomainUrlPutUserSubscription: `${URL_API}/domain/users/subscription`,
    apiDomainUrlPostUserFileDownloaded: `${URL_API}/domain/users/register/downloaded/file`,
    apiDomainUrlGetMonthly: `${URL_API}/domain/monthly`,
    apiDomainFileExtensionConfigurationAll: `${URL_API}/domain/fileextensionconfigurations/getall/`,
    apiDomainFileExtensionConfigurationByIdConfiguration: `${URL_API}/domain/fileextensionconfigurations/getallbyconfigurationid/`,
    apiDomainFileExtensionConfigurationByFileType: `${URL_API}/domain/fileextensionconfigurations/getall/byfiletype/`,
    apiDomainFileConfigurationAll: `${URL_API}/domain/fileconfigurations/getall/`,
    apiDomainFileConfigurationByFileType: `${URL_API}/domain/fileconfigurations/getallbyfiletype/`,
    apiDomainFileConfigurationByFileTypeEnabled: `${URL_API}/domain/fileconfigurations/getallbyfiletypeenabled/`,
    apiDomainUrlNewsletters: `${URL_API}/domain/newsletter`,

    apiDomainGetNewsForCarousel: `${URL_API}/domain/news/getall/forcarousel/`,

    /** URIs MonthlyGrids */
    apiMonthlyUrlDownload: `${URL_API}/monthly/monthlyshowsdownload`,
    apiMonthlyUrlUpload: `${URL_API}/monthly/monthlyshowsupload`,
    apiMonthlyUrlSendFiles: `${URL_API}/epg/tapkitFrontEnd/monthly/uploadFiles`,

    /** URIs DailyGrids */
    apiDailyGridsUrlDownload: `${URL_API}/daily/shows.xls`,

    /** URIs DatacityLogs */
    apiDatacityUrlGetProcess: `${URL_API}/datacity/getprocess`,
    apiDatacityUrlGetFiles: `${URL_API}/datacity/getfiles/byid_process/`,
    apiDatacityUrlGetStatus: `${URL_API}/datacity/getfilesstatus`,
    apiDatacityUrlGetFiltered: `${URL_API}/datacity/getfiltered`,
    apiDatacityUrlGetLogs: `${URL_API}/datacity/getlogs/byid_file/`,
    apiDatacityUrlGetFeeds: `${URL_API}/datacity/getfeeds`,

    /** URIs Ancine */
    apiAncineGridsFileGetFileDate: `${URL_API}/domain/ancine/getfile/network`,
    apiAncineGridsFileGetDownloadedFile: `${URL_API}/epg/tapkitFrontEnd/ancine/downloadfile`,
    apiAncineGridsFileGetDownloadedFiles: `${URL_API}/epg/tapkitFrontEnd/ancine/downloadfiles`,

    /** URIs NetworkEpg */
    apiNetworEpgGetAllNetwork: `${URL_API}/domain/networkepg/getallnetwork`,
    apiNetworkEpgGetActiveNetworks: `${URL_API}/domain/networkepg/getactivenetworks`,
    /** URIs NetworkTab */
    apiNetworEpgGetAllNetworkTab: `${URL_API}/domain/networktab/getallnetworktab`,
    /** URIs NetworkGrids */
    apiNetworEpgGetAllNetworkGrids: `${URL_API}/domain/networkgrids/getfilesfilteredbymonths`,
    apiNetworEpgGetAllEpakFiles: `${URL_API}/domain/networkgrids/getepakfiles`,
    apiNetworEpgGetUpdates: `${URL_API}/domain/networkgrids/updates`,
    apiNetworEpgGetAllNetworkLocalBreaksByDate: `${URL_API}/domain/networkgrids/getlocalbreaksfilesfilteredbydate`,
    apiNetworksGridsFileGetDownloadedFiles: `${URL_API}/epg/tapkitFrontEnd/networksepg/downloadfiles`,
    /** URIs NetworkContacts */
    apiNetworksContactsGetAllNetworkContacts: `${URL_API}/domain/networkcontacts/getallcontacts`,
    /** URIs Network LocalBreaks */
    apiNetworksLocalBreaksDownloadLocalBreaksFile: `${URL_API}/epg/tapkitLocalBreaks/downloadfile`,
     /** URIs Network Upload */
     apiDomainPostNetworkFile: `${URL_API}/domain/networks/upload`,
     apiDomainGetNetworkFilesByUser: `${URL_API}/domain/tapkitfiles/getfiles/byusername`,
     apiDomainDeleteFile: `${URL_API}/domain/tapkitfiles/deletefile`,
     apiEpgDeleteFileFromS3: `${URL_API}/epg/tapkitFrontEnd/networkUpload/deleteFile`,
    /** URIs EpgNews */
    apiEpgGetAllNews: `${URL_API}/domain/news/getall`,
    apiEpgGetAllNewsByNetworkId: `${URL_API}/domain/news/getall/bynetworkid`,
    apiEpgUploadNewsFiles: `${URL_API}/epg/tapkitFrontEnd/news/uploadFiles`,
    apiEpgDeleteNewsImageFromS3:`${URL_API}/epg/tapkitFrontEnd/news/deleteImage`,
    apiEpgUploadNewsDB: `${URL_API}/domain/news/uploadDB`,
    apiEpgDeleteNewsDB: `${URL_API}/domain/news/delete/byid`,
    // apiEpgUpdateNewsDB: `${URL_API}/domain/news/updateDB`,
    /** URIs Cloud-Security Service */
    apiSecurityUrl: `${URL_API}/security/oauth/token`,
    apiSecurityUrlResetPassword: `${URL_API}/security/users/reset`,
    apiSecurityUrlRegister: `${URL_API}/security/users/save`,
    apiSecurityUrlChangePassword: `${URL_API}/security/users/changePassword`,
    apiSecurityUrlRegisterMailValidation: `${URL_API}/security/email/validate`,
    apiSecurityUrlCategory: `${URL_API}/security/select/categories`,
    apiSecurityUrlCountry: `${URL_API}/security/select/countries`,
    apiSecurityUrlLanguage: `${URL_API}/security/select/languages`,

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
