import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RegisterComponent } from './register/register.component';
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { RegisterRoutingModule } from "./register-routing.module";
import { TermsComponent } from "./terms/terms.component";

@NgModule({
    declarations: [
        RegisterComponent,
        TermsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
        ReactiveFormsModule,
        RouterModule,
       
        RegisterRoutingModule
    ]
})
export class RegisterModule { }
