import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { LanguageEnum } from 'src/app/shared/constants/language.enum';
import { UserProfile } from 'src/app/shared/model/user-profile';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

    @Input() user: UserProfile;

    usernametCtrl = new FormControl('', Validators.required);
    langCrtl = new FormControl('', Validators.required);

    langList = Object.keys(LanguageEnum).map(key => ({ value: key, name: LanguageEnum[key] }));

    readonlyEmail = true;
    disabledLang = true;

    constructor() {
     }

    ngOnInit(): void {
        this.usernametCtrl.setValue(this.user.username);
        this.langCrtl.setValue(this.user.language);
    }




}
