import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NetworkEpg } from '../model/network-epg-model/network-epg';
import { saveAs } from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class NetworkEpgService {

  constructor(private http: HttpClient) { }

  getAllNetwork(){
    return this.http.get<NetworkEpg[]>(environment.apiNetworEpgGetAllNetwork);
  }

  getActiveNetworks(){
    return this.http.get<NetworkEpg[]>(environment.apiNetworkEpgGetActiveNetworks);
  }

  downloadFile(url: string): void {
    let filename = url.split('/').pop();
    const blob = new Blob([url]);
    saveAs(blob,filename);
  }

}
