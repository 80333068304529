import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators'
import { GeneralService } from './general.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UserProfileResponse } from '../response/user-profile-response';
import { RoleType } from '../model/role-type';
import { UserProfile } from '../model/user-profile';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private http: HttpClient,
    private gralService: GeneralService
  ) { }


  getUserProfile$(page: number, size: number, sortFiel?:String, sortDirection?:string, keyword?:string ): Observable<UserProfileResponse> {

    if (sortFiel === undefined || sortFiel === null){
        sortFiel = 'username';
    }

    if (sortDirection === undefined || sortDirection === null || sortDirection === ''){
        sortDirection = 'asc';
    }

    let url = environment.apiDomainUrlGetUserProfiles+`?page=${page}&size=${size}&sortBy=${sortFiel},${sortDirection}`;
    if(keyword !== undefined && keyword !== null && keyword !== '')
        url = environment.apiDomainUrlGetUserProfiles+`?page=${page}&size=${size}&sortBy=${sortFiel},${sortDirection}&keyword=${keyword}`;

    return this.http
        .get<UserProfileResponse>(url)
        .pipe(
            catchError(this.gralService.handleError));
  }


  getUserProfilesToNewsletter(networks:number[], countries:string[], language:string, onlyTurnerEmployees?:boolean): Observable<UserProfileResponse> {

    var params = new HttpParams();

    if (networks !== undefined && networks !== null && networks.length > 0)
        params = params.set('networks', networks.toString());

    if (countries !== undefined && countries !== null && countries.length > 0)
        params = params.set('countries', countries.toString());

    if (language !== undefined && language !== null && language !== '')
        params = params.set('language', language);

    if (onlyTurnerEmployees !== undefined && onlyTurnerEmployees !== null)
        params = params.set('onlyTurnerEmployees', onlyTurnerEmployees.toString());


    return this.http
        .get<UserProfileResponse>(environment.apiDomainUrlGetUserProfiles+`/getToNewsletters`,{ params })
        .pipe(
            catchError(this.gralService.handleError));
  }

  getUserProfileByUsername$(username: string): Observable<UserProfileResponse> {
    return this.http
        .get<UserProfileResponse>(environment.apiDomainUrlGetUserProfiles+`/${username}`)
        .pipe(
            catchError(this.gralService.handleError));
  }

  getUserProfileById$(id: number): Observable<UserProfileResponse> {
    return this.http
        .get<UserProfileResponse>(environment.apiDomainUrlGetUserProfiles+`/id/${id}`)
        .pipe(
            catchError(this.gralService.handleError));
  }

  updateUserProfileStatus$(id: number, state: number): Observable<UserProfileResponse> {

    return this.http
        .patch<UserProfileResponse>(environment.apiDomainUrlGetUserProfiles+`/${id}/state?state=${state}`, null)
        .pipe(
            catchError(this.gralService.handleError));
  }

  updateProfileStatus(ids: number[], state: number): Observable<UserProfileResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');


    return this.http
        .patch<UserProfileResponse>(environment.apiDomainUrlGetUserProfiles+`/updatestates?state=${state}&usersIds=${ids.toString()}`,{
            headers: headers,
            //params: {usersIds: ids.toString()}
        })
        .pipe(
            catchError(this.gralService.handleError));
  }


  updateRole$(user: UserProfile): Observable<UserProfileResponse> {

    return this.http
        .put<UserProfileResponse>(environment.apiDomainUrlGetUserProfiles + `/addRoles`, user)
        .pipe(
            catchError(this.gralService.handleError));
  }

  searchUser$(search: string): Observable<UserProfileResponse> {
    return this.http
        .get<UserProfileResponse>(environment.apiDomainUrlGetUserProfiles+`/search?keyword=${search}`)
        .pipe(
            catchError(this.gralService.handleError));
  }

  removeUserProfile(id: number): Observable<UserProfileResponse> {
    return this.http
        .delete<UserProfileResponse>(environment.apiDomainUrlGetUserProfiles+`/remove?id=${id}`)
        .pipe(
            catchError(this.gralService.handleError));
  }

removeUsersProfiles(ids: number[]): Observable<UserProfileResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.http
        .delete<UserProfileResponse>(
            environment.apiDomainUrlGetUserProfiles + `/removeUsers`,
            {
                headers: headers,
                params: {usersIds: ids.toString()},

            }
        )
        .pipe(catchError(this.gralService.handleError));
    }

}
