import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserProfile } from 'src/app/shared/model/user-profile';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {

    @Input() user: UserProfile;

    nameCtrl = new FormControl('', Validators.required);
    lastNameCtrl = new FormControl('', Validators.required);
    companyCtrl = new FormControl('', Validators.required);
    countryCtrl = new FormControl('', Validators.required);
    cityCtrl = new FormControl('', Validators.required);
    addressCtrl = new FormControl('', Validators.required);
    zipCodeCtrl = new FormControl('', Validators.required);


    constructor() { }

    ngOnInit(): void {
        this.nameCtrl.setValue(this.user.firstName);
        this.lastNameCtrl.setValue(this.user.lastName);
        this.companyCtrl.setValue(this.user.company.name);
        this.countryCtrl.setValue(this.user.address.country);
        this.cityCtrl.setValue(this.user.address.city);
        this.addressCtrl.setValue(this.user.address.street);
        this.zipCodeCtrl.setValue(this.user.address.zipCode);

    }

}
