import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from '../shared/model/role';
import { EpgGuard } from '../shared/utils/auth.guard';
import { ConfigurationGridComponent } from './configuration-grid/configuration-grid.component';
import { EpgSubscriptionComponent } from './epg-subscription/epg-subscription.component';
import { RedirectAuthenticationComponent } from '../login/redirect-authentication/redirect-authentication.component';
import { EpgComponent } from './epg.component';
import { LegalComponent } from './legal/legal.component';
import { MonthlyComponent } from './monthly-grids/monthly/monthly.component';
import { VisualLogsComponent } from './visual-logs/visual-logs.component';
import { AncineComponent } from './ancine/ancine.component';
import { NetworksComponent } from './networks/networks/networks.component';
import { NetworksUploadComponent } from './networks-upload/networks-upload.component';
import { UsersComponent } from './users/users.component';
import { NewsComponent } from './news/news/news.component';
import { NewslettersComponent } from './newsletters/newsletters.component';
import { NetworkSubscriptionComponent } from './network-subscription/network-subscription.component';
import { ProfileComponent } from './profile/profile.component';



const routes: Routes = [
    {
        path: 'epg',
        component: EpgComponent,
        children: [
            {
                path: 'subscription',
                component: EpgSubscriptionComponent,
                canActivate: [EpgGuard],
                data: { allowedRoles: [Role.SuperAdmin, Role.Microservices] }
            },
            {
                path: 'subscription/:locale/:refresh_token',
                component: RedirectAuthenticationComponent,
                canActivate: [EpgGuard],
                data: { allowedRoles: [Role.SuperAdmin, Role.User] }
            },
            {
                path: 'configuration',
                component: ConfigurationGridComponent,
                canActivate: [EpgGuard],
                data: { allowedRoles: [Role.SuperAdmin] }
            },
            {
                path: 'legal',
                component: LegalComponent,
                canActivate: [EpgGuard],
                data: { allowedRoles: [Role.SuperAdmin, Role.Legal] }
            },
            {
                path: 'ancine',
                component: AncineComponent,
                canActivate: [EpgGuard],
                data: { allowedRoles: [Role.SuperAdmin, Role.Ancine], isAncine: true }
            },
            {
                path: 'networks',
                component: NetworksComponent,
                canActivate: [EpgGuard],
                data: { allowedRoles: [Role.SuperAdmin, Role.User, Role.Localbreaks] }
            },
            {
                path: 'news',
                component: NewsComponent,
                canActivate: [EpgGuard],
                data: { allowedRoles: [Role.SuperAdmin, Role.AdminContent] }
            }
        ]
    },
    {

        path: 'monthly',
        component: MonthlyComponent,
        canActivate: [EpgGuard],
        data: { allowedRoles: [Role.SuperAdmin, Role.AdminContent] }

    },
    {
        path: 'visual-logs',
        component: VisualLogsComponent,
        canActivate: [EpgGuard],
        data: { allowedRoles: [Role.SuperAdmin, Role.Logs] }
    },
    {
        path: 'upload-networks',
        component: NetworksUploadComponent,
        canActivate: [EpgGuard],
        data: { allowedRoles: [Role.SuperAdmin, Role.AdminContent] }
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: [EpgGuard],
        data: { allowedRoles:  [Role.User, Role.SuperAdmin ] }
    },
    {
        path: 'newsletters',
        component: NewslettersComponent,
        canActivate: [EpgGuard],
        data: { allowedRoles:  [Role.SuperAdmin, Role.AdminPress, Role.AdminContent] }
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [EpgGuard],
        data: { allowedRoles:  [Role.User, Role.SuperAdmin ] }
    },
    {
        path: 'network-subscription',
        component: NetworkSubscriptionComponent,
        canActivate: [EpgGuard],
        data: { allowedRoles:  [Role.User, Role.SuperAdmin ] }
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class EpgRoutingModule { }
