import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { LanguageEnum } from 'src/app/shared/constants/language.enum';
import { UserProfile } from 'src/app/shared/model/user-profile';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserProfileService } from 'src/app/shared/service/user-profile.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

    @Input() user: UserProfile;

    accountForm: FormGroup;

    loading = false;
    successUpdateAccount = false;
    errorUpdateAccount = false;

    langList = Object.keys(LanguageEnum).map(key => ({ value: key, name: LanguageEnum[key] }));


    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private userProfileService: UserProfileService
    ) {
     }

    ngOnInit(): void {
        console.log("user", this.user);
        this.accountForm = this.formBuilder.group({
            usernametCtrl: new FormControl({value:this.user.username, disabled:true},  Validators.required),
            langCrtl: new FormControl({value:this.user.language, disabled:false}, Validators.required)
        });

    }


    updateAccount(): void {
        this.loading = true;
        this.successUpdateAccount = false;
        this.errorUpdateAccount = false;
        this.user.language = this.accountForm.controls.langCrtl.value;
        this.userProfileService.updateProfile$(this.user).subscribe(
            () => {
                this.successUpdateAccount = true;
                this.loading = false;
            },
            (error) => {
                this.errorUpdateAccount = true;
                this.loading = false;
            }
        );
    }

}
